<template>
    <v-card outlined dense elevation-1 :width="width">
        <v-list-item v-if="item.productID">
            <v-list-item-avatar v-if="!isGridItem" class="d-none d-sm-block my-auto">
                <v-img :src="productLogoURL(item.productID)" class="my-auto">
                    <template v-slot:placeholder>
                        <v-icon class="primary--text" size="40">mdi-cube-outline</v-icon>
                    </template>
                </v-img>
            </v-list-item-avatar>
            <v-list-item-content>
                <v-row dense>
                    <v-col v-if="canEdit" :cols="isGridItem ? '12' : '7'" class="my-auto" dense>
                        <p class="text-center">{{ item.description }}</p>
                        
                        <v-text-field
                            required
                            dense
                            solo-inverted
                            :hide-details="isGridItem"
                            type="number"
                            :rules="quantityRules"
                            label="Quantity"
                            v-model.number="item.quantity"
                            @input="updateQuantity">
                            <template v-slot:prepend>
                                <v-icon tabIndex="-1" @click.stop="remove(1)">mdi-minus</v-icon>
                            </template>
                            <template v-slot:append-outer>
                                <v-icon tabIndex="-1" @click.stop="add(1)">mdi-plus</v-icon>
                            </template>
                        </v-text-field>

                    </v-col>
                    <v-col v-else cols="5" sm="auto" class="my-auto" dense>
                        <v-list-item-title>{{ item.description }}</v-list-item-title>
                        <v-list-item-subtitle>Qty: {{ item.quantity | toDisplayNumber }}</v-list-item-subtitle>
                    </v-col>

                    <v-spacer v-if="!isGridItem" />
                    
                    <v-col v-if="canEdit && isGridItem" cols="12">
                        <v-row>
                            <v-col cols="6">
                                <span class="text-left font-weight-bold caption">{{ item.amountTotal | toCurrency }}</span>
                            </v-col>
                            <v-col cols="6">
                                <v-edit-dialog>
                                    <v-list-item-subtitle class="text-right font-weight-bold caption">@{{ item.unitPrice | toCurrency }}ea</v-list-item-subtitle>
                                    <template v-slot:input>
                                        <v-text-field
                                            v-model.number="item.unitPrice"
                                            label="Unit Price"
                                            single-line
                                            class="text-right"
                                            prefix="$"
                                            type="number"
                                            @input="unitPriceChanged" />
                                    </template>
                                </v-edit-dialog>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col v-else-if="canEdit" cols="5" sm="auto" class="my-auto" dense>
                        <p class="text-right font-weight-bold" overline>{{ item.amountTotal | toCurrency }}</p>
                        <p v-if="!canEditPrice" class="text-right font-weight-light caption">@{{ item.unitPrice | toCurrency }} each</p>
                        <v-edit-dialog v-else>
                            <v-list-item-subtitle class="text-right font-weight-bold caption">@{{ item.unitPrice | toCurrency }} each</v-list-item-subtitle>
                            <template v-slot:input>
                                <v-text-field
                                    v-model.number="item.unitPrice"
                                    label="Unit Price"
                                    single-line
                                    class="text-right"
                                    prefix="$"
                                    type="number"
                                    @input="unitPriceChanged" />
                            </template>
                        </v-edit-dialog>
                    </v-col>
                    <v-col v-else cols="3" class="my-auto" dense>
                        <v-list-item-subtitle :class="isGridItem ? 'text-center' : ''" class="caption">(@{{ item.unitPrice | toCurrency }}ea)</v-list-item-subtitle>
                    </v-col>
                    <v-col v-if="!canEdit" cols="3" sm="auto" class="my-auto" dense>
                        <p class="text-right font-weight-bold my-auto" overline>{{ item.unitPrice * item.quantity | toCurrency }}</p>
                    </v-col>
                </v-row>
            </v-list-item-content>
        </v-list-item>
        <v-list-item v-else>
            <v-list-item-content>
                <v-row dense>
                    <v-spacer />
                    <p class="my-auto mr-5">{{ item.description }}</p>
                    <p class="mr-1 my-auto text-right font-weight-bold" overline>{{ item.unitPrice * item.quantity | toCurrency }}</p>
                </v-row>
            </v-list-item-content>
        </v-list-item>
    </v-card>
</template>

<script>
import { getUnitPrice, totalAmount } from '~tools/helpers.js';

export default {
    name: 'Customer-Order-Item',
    data: () => {
        return {
            quantityRules: [
            v => v >= 0 || 'Must be 0 or more',         
            ],
            incrementVal: 1
        }
    },
    props: {
        item: null,
        canEditPrice: {
            type: Boolean,
            default: false,
        },
        canEdit: {
            type: Boolean,
            default: false
        },
        isGridItem: {
            type: Boolean,
            default: false
        },
        width: {
            type: String,
            default: null
        }
    },
    created() {
        if (this.item.data) {
            var rule = this.item.data.rules.find(x => x.rule === 'QuantityInIncrements');
            if (rule) {
                this.incrementVal = rule.quantity;
                this.quantityRules.push(v => v % this.incrementVal == 0 || 'Order in increments of ' + this.incrementVal);
            }
        }        
    },
    methods: {
        unitPriceChanged() {
            this.item.amountTotal = totalAmount(this.item);
            this.$emit('input', this.item);
            this.$emit('change', this.item);
        },
        update() {
            if (Number.isNaN(this.item.quantity)) {
                this.item.quantity = 0;
            }

            this.item.unitPrice = getUnitPrice(this.item.data.unitPriceIncrements, this.item.quantity);
            this.item.amountTotal = totalAmount(this.item); //this.item.quantity * this.item.unitPrice;
            this.$emit('input', this.item);
            this.$emit('change', this.item);
        },
        add(inc) {            
            var rule = this.item.data.rules.find(x => x.rule === 'QuantityInIncrements');
            var qtyToAdd = inc;
            
            if (rule) {
                qtyToAdd = qtyToAdd * rule.quantity;
            }

            this.item.quantity = parseFloat(this.item.quantity) + parseFloat(qtyToAdd);            

            this.update();
        },
        remove(inc) {
            var rule = this.item.data.rules.find(x => x.rule === 'QuantityInIncrements');
            var qtyToAdd = inc;

            if (rule) {
                qtyToAdd = qtyToAdd * rule.quantity;
            }

            this.item.quantity = parseFloat(this.item.quantity) - parseFloat(qtyToAdd);            
                        
            if (this.item.quantity < 0) {
                this.item.quantity = 0;
            }

            this.update();
        },
        updateQuantity(val) {            
            this.item.quantity = parseFloat(val);
            this.update();
        }
    }
}
</script>